<template>
    <v-dialog v-model="dialog" tile flat max-width="80%">
        <template v-slot:activator="{ on, attrs }">
            <v-btn text small block v-bind="attrs" v-on="on">View</v-btn>
        </template>
        <v-card class="pa-8" max-width="100%" outlined tile>
            <div class="d-flex flex-row">
                <h2 class="pa-0">
                    <strong>{{ value.title }}</strong>
                </h2>
                <v-spacer></v-spacer>
                <v-btn icon class="subsecondary" @click="dialog = false">
                    <v-icon color="secondary">{{ icons.mdiClose }}</v-icon>
                </v-btn>
            </div>
            <div>
                <v-list-item two-line class="pa-0">
                    <v-list-item-avatar size="50" tile class="secondary">
                        <!-- <v-img src="@/assets/images/avatars/profil.svg"></v-img> -->
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-subtitle>{{ value.company.name }}</v-list-item-subtitle>
                        <v-list-item-subtitle>
                            <v-icon>{{ icons.mdiEyeOffOutline }}</v-icon>
                            <span>{{ value.location + ',' + value.country.name }}</span>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </div>
            <v-divider></v-divider>
            <div class="d-flex flex-row my-4">
                <span class="mr-4">
                    <v-icon>{{ icons.mdiBriefcase }}</v-icon>
                    {{ value.periodicity }}
                </span>
                <v-spacer></v-spacer>
                <span class="error--text">
                    <v-icon color="error" class="mr-1">{{ icons.mdiAlertOutline }}</v-icon>
                    Dateline: {{ value.dateline }}
                </span>
            </div>
            <v-divider></v-divider>
            <div class="px-0" v-html="value.description"></div>
            <div class="d-flex" v-if="submit === true">
                <v-spacer></v-spacer>
                <v-btn text color="secondary" class="subsecondary text">apply for the job</v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import { mdiClose, mdiHelpCircleOutline, mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiBriefcase } from '@mdi/js'
import { VueEditor } from "vue2-editor";
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'

export default {
    props: {
        value: {
            type: Object,
            default: () => { }
        },
        submit: {
            type: Boolean,
            default: false
        }
    },
    components: {

    },
    data() {
        return {
            dialog: false
        }
    },

    computed: {

    },
    methods: {

    },

    setup() {
        const icons = { mdiClose, mdiHelpCircleOutline, mdiCalendar, mdiKeyboardBackspace, mdiAlertOutline, mdiBriefcase }

        return {
            icons
        }
    },

}
</script>
<style lang="scss" scoped>
@import '@/styles/styles.scss';
</style>